import { HttpErrorResponse } from '@angular/common/http';

export const errorToMessageString = (err: any) => {
  let result = ""

  if (err instanceof HttpErrorResponse) {
    if (typeof err.error === "string" && err.error?.startsWith("{\"error\":")) {
      try {
        const nestedError = JSON.parse(err.error)
        result = `[HTTP ${err.status}] ${nestedError.error} "${nestedError.error_description}"`
      } catch (jsonError) {
        result = `[HTTP ${err.status}] "${err.message}"`
      }
    } else if (err.error.error) {
      return `[HTTP ${err.error.error.code}] "${err.error.error.message}"`
    } else {
      result = `[HTTP ${err.status}] "${err.message}"`
    }
  } else if (err instanceof Event) {
    result = `[Event] Type:${err.type} Target:${(err.target as any)?.constructor?.name}`
  } else if (err instanceof Error) {
    result = `[${err.name}] "${err.message}"`
  } else if (err.toString) {
    result = err.toString()
  } else {
    result = err
  }

  return result
}