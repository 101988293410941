// import { CanActivateFn } from '@angular/router';

// export const isAuthenticatedGuard: CanActivateFn = (route, state) => {
//   return true;
// };
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedGuard {
  public constructor(private auth: AuthService, private router: Router) {}

  public canActivate():
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree {
    if (this.auth.isLoggedIn() && this.auth.hasAwsCredentials()) {
      return true;
    } else {
      return this.router.parseUrl('/login');
    }
  }
}
