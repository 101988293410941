import { HttpClient } from "@angular/common/http"
import { EventEmitter, Injectable } from "@angular/core"
import { AuthConfig, OAuthEvent, OAuthService } from "angular-oauth2-oidc"
import { BehaviorSubject, filter, firstValueFrom, take } from "rxjs"
import { BringMeHomeConfig } from "src/environments/bring-me-home-config"

import { errorToMessageString } from "../utils/errors"
import { NGXLogger } from "ngx-logger"

export interface OAuthClaims {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  given_name: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  family_name: string;
  nickname: string;
  name: string;
  picture: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  updated_at: string;
  email: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  email_verified: true;
  iss: string;
  sub: string;
  aud: string;
  iat: number;
  exp: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  at_hash: string;
  nonce: string;
}

export interface AWSGetIDResult {
  IdentityId: string;
}

export interface AWSGetCredentialsForIdentityResult {
  IdentityId: string;
  Credentials: {
    AccessKeyId: string;
    Expiration: number;
    SecretKey: string;
    SessionToken: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public awsTokensRefreshed = new EventEmitter<void>() 
  
  public userName: string | undefined = undefined;
  public email: string | undefined = undefined;
  public awsAccessKeyId = ""
  public awsSecretKey = ""
  public awsSessionToken = ""

  private isLoggedInSubject = new BehaviorSubject(false)
  private refreshOnGoing = false

  public waitForLoginOnce$ = this.isLoggedInSubject
    .pipe(
      filter(isLoggedIn => isLoggedIn),
      take(1)
    )
  private awsTimeout: NodeJS.Timeout | undefined

  public constructor(
    private logger: NGXLogger,
    private oauthService: OAuthService,
    private http: HttpClient
  ) {

    this.oauthService.setStorage(localStorage);

    this.configureOAuthService();
    this.oauthService.events.subscribe(async event =>
      this.onOauthEvent(event)
    );

    if (this.isLoggedIn()) {
      this.logger.log('User already logged in.');
      this.updateUserInfo();
      this.updateAWSCredentials();
    }
  }

  public get userInfo(): OAuthClaims {
    return this.identityClaims;
  }

  private get identityClaims(): OAuthClaims {
    return this.oauthService.getIdentityClaims() as OAuthClaims;
  }

  public hasAwsCredentials(): boolean {
    return !!this.awsAccessKeyId && !!this.awsSecretKey
  }

  public async init() {
    if (!this.isLoggedIn()) {
      await this.refresh();
    }
  }

  public isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  public async logIn() {
    await this.oauthService.loadDiscoveryDocument();
    await this.oauthService.initCodeFlow();
  }

  public logOut() {
    this.oauthService.logOut(false);
  }

  private async updateAWSCredentials() {
    try {
      const Logins: any = {};
      Logins[BringMeHomeConfig.auth.issuer.replace('https://', '')] =
        this.oauthService.getIdToken();

      const identityResult = await firstValueFrom(
        this.http.post<AWSGetIDResult>(
          'https://cognito-identity.eu-central-1.amazonaws.com/',
          {
            IdentityPoolId: BringMeHomeConfig.auth.identityPoolID,
            Logins
          },
          {
            headers: {
              'x-amz-target': 'AWSCognitoIdentityService.GetId',
              'content-type': 'application/x-amz-json-1.1'
            }
          }
        )
      );

      const credentialResults = await firstValueFrom(
        this.http.post<AWSGetCredentialsForIdentityResult>(
          'https://cognito-identity.eu-central-1.amazonaws.com/',
          {
            IdentityId: identityResult.IdentityId,
            Logins
          },
          {
            headers: {
              'x-amz-target': 'AWSCognitoIdentityService.GetCredentialsForIdentity',
              'content-type': 'application/x-amz-json-1.1'
            }
          }
        )
      );

      this.awsAccessKeyId = credentialResults.Credentials.AccessKeyId;
      this.awsSecretKey = credentialResults.Credentials.SecretKey;
      this.awsSessionToken = credentialResults.Credentials.SessionToken

      const awsExpiration = credentialResults.Credentials.Expiration * 1000 - Date.now()
      this.logger.info(`AWS credentials expiring in ${this.expirationToString(credentialResults.Credentials.Expiration)}`)

      clearTimeout(this.awsTimeout)
      this.awsTimeout = setTimeout(() => {
        this.updateAWSCredentials();
      }, awsExpiration * 0.8)
      this.awsTokensRefreshed.emit()


      this.isLoggedInSubject.next(true)

    } catch (err) {
      this.logger.error(`Failed to retrieve AWS credentials. Reason: ${errorToMessageString(err)}`);
      return false;
    }

    return true;
  }

  public async evaluateCallback(): Promise<boolean> {
    return this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  private expirationToString(expirationInSeconds: number) {
    let diff = Math.floor(expirationInSeconds - Date.now() / 1000)
    const hours = Math.floor(diff / 3600); diff -= hours * 3600
    const minutes = Math.floor(diff / 60); diff -= minutes * 60
    const seconds = Math.floor(diff)

    return `${hours}h ${minutes}min ${seconds}s`
  }

  private updateUserInfo() {
    this.userName =
      this.userInfo.given_name && this.userInfo.family_name
        ? `${this.userInfo.given_name} ${this.userInfo.family_name}`
        : this.userInfo.name;
    this.email = this.userInfo.email;

    this.logger.log(`Azure AD credentials expiring in ${this.expirationToString(this.userInfo.exp)}`)
  }


  private async refresh(): Promise<boolean> {
    if (this.refreshOnGoing) {
      return false
    }

    this.refreshOnGoing = true

    if (!this.oauthService.getRefreshToken()) {
      this.logger.warn('No refresh token found. Aborting refresh request.');
      this.refreshOnGoing = false
      return false;
    }

    try {
      await this.oauthService.loadDiscoveryDocument();
      await this.oauthService.refreshToken();
      this.refreshOnGoing = false
      return true;
    } catch (err) {
      this.logger.error(`Refreshing access token failed. Reason: ${errorToMessageString(err)}`);
      this.refreshOnGoing = false
      return false;
    }
  }

  private configureOAuthService() {
    const authCodeFlowConfig: AuthConfig = {
      // Url of the Identity Provider
      issuer: BringMeHomeConfig.auth.issuer,

      // URL of the SPA to redirect the user to after login
      redirectUri: this.getPlatformCallbackUrl(),
      logoutUrl: this.getPlatformCallbackUrl(),
      clientId: BringMeHomeConfig.auth.clientId,
      responseType: 'code',
      scope: BringMeHomeConfig.auth.scope,
      showDebugInformation: true,
      customQueryParams: BringMeHomeConfig.auth.options,
      skipIssuerCheck: BringMeHomeConfig.auth.skipIssuerCheck,
      strictDiscoveryDocumentValidation:
        BringMeHomeConfig.auth.strictDiscoveryDocumentValidation,

      openUri: this.openUrl.bind(this)
    };

    this.oauthService.configure(authCodeFlowConfig);
  }


  private getPlatformCallbackUrl(): string {
    return window.location.href.replace(/#.*/, "").replace(/\?.*/, "")
  }

  private async openUrl(url: string) {
    await window.open(url, '_self');
  }

  private onOauthEvent(event: OAuthEvent) {
    this.logger.info(`onOauthEvent: ${event.type}`);
    if (event) {
      switch (event.type) {
        case 'token_received':
          this.updateUserInfo();
          this.updateAWSCredentials();
          break;
        case 'token_expires':
          this.refresh()
          break;
      }
    }
  }
}
