import { Component } from '@angular/core';

import { SettingsService } from './services/settings.service';

import { RequestService } from './services/request.service';
import { WebsocketService } from './services/websocket.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  selectedValue = this.settingsService.language;

  constructor(
    private settingsService: SettingsService,
    private requestService: RequestService,
    private wsService: WebsocketService
  ) {
    this.wsService.connect()
    this.requestService.checkSubscription();
  }
}
