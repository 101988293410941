import { Injectable } from "@angular/core"
import { NGXLogger } from "ngx-logger"
import { BehaviorSubject, catchError, delayWhen, EMPTY, filter, map, Observable, retryWhen, Subscription, tap, timer } from "rxjs"
import { webSocket, WebSocketSubject } from "rxjs/webSocket"

import { errorToMessageString } from "../utils/errors"

const WS_ENDPOINT = "wss://pflf2intn4.execute-api.eu-central-1.amazonaws.com/production";
const RECONNECT_INTERVAL = 2000
let LOGGER: NGXLogger | undefined = undefined

export interface IWSMessage {
  from: string
  to?: string
  command: string
  parameters?: Record<string, string | number>  
}


@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private newMessages = new BehaviorSubject<IWSMessage | undefined>(undefined)
  public newMessages$ = this.newMessages.pipe(filter(el => !!el), map(el => el!))

  private socket$: WebSocketSubject<IWSMessage> | undefined = undefined;
  private deviceID: string = crypto.randomUUID()
  private newMessageSubscription: Subscription | undefined

  private isConnected = new BehaviorSubject<boolean>(false)
  public isConnected$ = this.isConnected.asObservable()

  public constructor(private logger: NGXLogger) {
    LOGGER = this.logger
  }

  public connect(reconnect = false): void {
    if (!this.socket$ || this.socket$.closed) {
      this.newMessageSubscription?.unsubscribe()

      this.socket$ = this.getNewWebSocket();
      this.newMessageSubscription = this.socket$
        .pipe(
          reconnect ? this.reconnect : o => o,
          tap({
            error: err => LOGGER?.error(`Websocket Error: ${errorToMessageString(err)}`)
          }), catchError(_ => EMPTY))
        .subscribe(el => {
          this.newMessages.next(el)
        })
    }
  }

  private reconnect(observable: Observable<any>): Observable<any> {
    return observable.pipe(retryWhen(errors => errors.pipe(tap(val => LOGGER?.log('Try to reconnect to websocket')),
      delayWhen(_ => timer(RECONNECT_INTERVAL)))));
  }

  private getNewWebSocket() {
    return webSocket<IWSMessage>(
      {
        url: WS_ENDPOINT,
        openObserver: {
          next: () => {
            this.isConnected.next(true)
            this.logger.log(`CONNECTING to ${WS_ENDPOINT}`)
          }
        },
        closeObserver: {
          next: () => {
            this.isConnected.next(false)
            this.logger.error('Websocket connection closed');
            this.socket$ = undefined;
            this.connect(true);
          }
        },
        serializer: value => JSON.stringify({ action: "sendmessage", message: JSON.stringify(value) })
      });
  }

  public send(command: string, parameters?: Record<string, string | number>) {
    this.socket$?.next({
      command,
      parameters,
      from: this.deviceID
    });
  }

  public close() {
    this.newMessageSubscription?.unsubscribe()
    this.socket$?.complete();
  }
}