<si-landing-page
  heading="BringMeHome"
  subtitle=""
  [links]="[
    {
      title: 'Corporate information',
      href: 'https://www.mobility.siemens.com/global/en/general/legal.html'
    },
    { 
      title: 'Privacy notice', 
      href: 'https://www.mobility.siemens.com/global/en/general/privacy-notice.html' 
    },
    { 
      title: 'Terms of use', 
      href: 'https://www.mobility.siemens.com/global/en/general/terms-of-use.html' 
    }
  ]"
  [availableLanguages]="availableLanguages"
  backgroundImageUrl="assets/images/login_screen_2.jpg"
>
  <button
    type="submit"
    class="btn btn-primary w-100"
    (click)="onLoginClicked()"
    [disabled]="isLoggingIn"
  >
    <span *ngIf="isLoggingIn" style="margin-right: 8px" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    {{ "APP.LOGIN" | translate }}
  </button>
</si-landing-page>
