import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { AuthService } from "src/app/services/auth.service"

import { SettingsService } from "../../services/settings.service"

@Component({
  templateUrl: './landing.component.html'
})
export class LandingComponent implements OnInit {
  availableLanguages = this.settingsService.availableLanguages;
  public isLoggingIn = false;

  constructor(
    private settingsService: SettingsService,
    private auth: AuthService,
    private router: Router
  ) {}
  public async ngOnInit() {
    await this.auth.init();

    this.auth.waitForLoginOnce$.subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.router.navigate(['/main/home']);
      }
    })

    if (document.URL.includes('code=')) {
      this.isLoggingIn = true;
      this.auth.evaluateCallback();
    } 
  }

  public onLoginClicked(): void {
    this.isLoggingIn = true;
    this.auth.logIn();
  }

}
