export const BringMeHomeConfig = {
  name: 'DEV',
  urlScheme: 'bringmehomedev',
  auth: {
    identityPoolID: 'eu-central-1:99b5f509-8dfe-42e6-a3b9-851783a01bc6',
    issuer:
      'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_RAOL2m1oc',

    clientId: 'c2nvic2eoegg2re9plbn8m211',
    scope: 'openid profile email',
    options: {
      identity_provider: 'SiemensID'
    },
    skipIssuerCheck: true,
    strictDiscoveryDocumentValidation: false
  },
  mapbox_token:
    'cGsuZXlKMUlqb2laM1ZwWkc5elkyaHliMlZsY2lJc0ltRWlPaUpqYkc1NU9UQXlhVEF3Ym5NNU1teHhiMnBxZFdoclpXaDJJbjAuNTJiY01Fbm4wZ3RzUU10dFNQelJDdw=='
};
