import { Injectable } from "@angular/core"
import { NGXLogger } from "ngx-logger"

import { WebsocketService } from "./websocket.service"

@Injectable({
  providedIn: 'root'
})
export class Peer2PeerService {
  public newMessages$ = this.ws.newMessages$

  public constructor(private logger: NGXLogger, private ws: WebsocketService) {
    this.newMessages$.subscribe(msg => {
      this.logger.trace(`RECEIVED [${msg.from}] ${msg.command} ` + (msg.parameters ? JSON.stringify(msg.parameters) : ""))
    })
  }

  public acceptRemoteControlRequest() {
    return this.ws.send("ACCEPT_REMOTE_CONTROL");
  }

  public rejectRemoteControlRequest() {
    return this.ws.send('REJECT_REMOTE_CONTROL');
  }

  public queryInfrastructureDevices() {
    return this.ws.send('QUERY_INFRASTRUCTURE_DEVICES');
  }

  public queryBringMeHomeDevices() {
    return this.ws.send('QUERY_BRINGMEHOME_DEVICES');
  }

  public setAcceleration(value: number) {
    return this.ws.send("SET_ACCELERATION", { value });
  }

  public emergencyStop() {
    return this.ws.send("EMERGENCY_STOP");
  }
}
