import { Inject, Injectable } from '@angular/core';
import { Peer2PeerService } from './peer2peer.service';
import {
  ConfirmationDialogResult,
  SiActionDialogService
} from '@simpl/element-ng';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  constructor(
    private peerService: Peer2PeerService,
    @Inject(SiActionDialogService) private siModal: SiActionDialogService,
    private translate: TranslateService,
    private router: Router,
    private logger: NGXLogger
  ) {}

  public isRemoteRequestAccepted$ = new BehaviorSubject<boolean>(false);
  public isOperatorAvailable = new BehaviorSubject<boolean>(true);

  public checkSubscription() {
    this.peerService.newMessages$.subscribe(el => {
      if (el?.command === 'REQUEST' && this.isOperatorAvailable.value) {
        this.logger.info('REQUEST arrived');
        this.isOperatorAvailable.next(false);

        const userName = (el.parameters?.['user'] as string) ?? ""
        const deviceName = (el.parameters?.['deviceName'] as string) ?? ""

        this.showDialog(el.from, userName, deviceName);
      }
    });
  }
  public async showDialog(deviceID: string, userName: string, deviceName: string) {
    const msgTitle = await lastValueFrom(
      this.translate.get('REMOTE_VIEW.CONFIRMATION_TITLE')
    );
    const msgDesc = await lastValueFrom(
      this.translate.get('REMOTE_VIEW.CONFIRMATION_DESC', {
        deviceID,
        userName,
        deviceName
      })
    );
    const confirmBtn = await lastValueFrom(
      this.translate.get('REMOTE_VIEW.CONFIRMATION_ACCEPTBTN')
    );
    const rejectBtn = await lastValueFrom(
      this.translate.get('REMOTE_VIEW.CONFIRMATION_REJECTBTN')
    );

    this.siModal
      .showConfirmationDialog(msgDesc, msgTitle, confirmBtn, rejectBtn)
      .subscribe(confirmation => {
        switch (confirmation) {
          case ConfirmationDialogResult.Confirm:
            this.onRemoteRequestAccepted(deviceID);
            this.logger.info('Dialog is confirmed!');
            break;
          case ConfirmationDialogResult.Decline:
            this.logger.info('Dialog is declined!');
            this.isOperatorAvailable.next(true);
            break;
        }
      });
  }
  public async onRemoteRequestAccepted(ID: string) {
    await this.peerService.acceptRemoteControlRequest();
    this.isRemoteRequestAccepted$.next(true);
    this.router.navigateByUrl('/main/operation-center/' + ID);
  }
}
