import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  SI_COPYRIGHT_DETAILS,
  SiActionDialogModule,
  SiLandingPageModule,
  SiLoadingSpinnerModule
} from '@simpl/element-ng';
import { SiTranslateNgxTModule } from '@simpl/element-ng/ngx-translate';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import { AppComponent } from './app.component';
import { IsAuthenticatedGuard as AuthGuard } from './guards/is-authenticated.guard';
import { LandingComponent } from './pages/landing/landing.component';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

const routes: Routes = [
  { path: 'landing', component: LandingComponent },
  {
    path: 'main',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/main/main.module').then(m => m.MainModule)
  },
  { path: '**', redirectTo: 'landing' }
];

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const oauthStorageFactory = () => localStorage;

@NgModule({
  declarations: [AppComponent, LandingComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, { useHash: true }),
    OAuthModule.forRoot(),
    SiLandingPageModule,
    SiTranslateNgxTModule,
    SiLoadingSpinnerModule,
    SiActionDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.ERROR,
      disableConsoleLogging: false,
      enableSourceMaps: true
    })
  ],
  providers: [
    { provide: OAuthStorage, useFactory: oauthStorageFactory },
    {
      provide: SI_COPYRIGHT_DETAILS,
      useValue: {
        company: "Siemens Mobility GmbH",
        startYear: 2023,
        lastUpdateYear: 2024
      }
    }
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent]
})
export class AppModule {}
